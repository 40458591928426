import { useState } from 'react';

import { VegaFlex } from '@heartlandone/vega-react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import { PaymentTypeDataResult } from 'types/reports';
import { DeviceIsMobile } from 'utility/showOnDevice';

import { typeColors, unselectedSliceIndex } from '../chart.constants';
import CustomTooltip from '../customTooltip';
import LabelMarker from '../labelMarker';

import styles from './paymentTypeDonut.module.css';

interface PaymentTypeDonutProps {
  data: Array<PaymentTypeDataResult> | undefined;
}

const PaymentTypeDonut: React.FC<PaymentTypeDonutProps> = ({ data }) => {
  const [selected, setSelected] = useState<number | undefined>(unselectedSliceIndex);

  const isMobile = DeviceIsMobile();

  const handleSelected = (index: number) => {
    setSelected(index == selected ? unselectedSliceIndex : index);
  };

  return (
    <VegaFlex
      className={styles.paymentTypeChartContainer}
      direction={{ XL: 'row', L: 'row', M: 'row', S: 'col' }}
      gap={'size-48'}
      style={{ width: '100%' }}
      data-testid="payment-type-chart-container"
    >
      <PieChart width={210} height={210} defaultShowTooltip>
        <Pie data={data} cx={100} cy={100} innerRadius={60} outerRadius={90} dataKey="paymentTypeTotalAmount">
          {data!.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={typeColors[entry.index]}
              style={{ outline: 'none', cursor: 'pointer' }}
              stroke={typeColors[entry.index]}
              strokeWidth={selected === index ? 8 : 0}
              className={styles.pieSector}
              onClick={() => handleSelected(index)}
            />
          ))}
        </Pie>
        {!isMobile && <Tooltip content={<CustomTooltip />} />}
      </PieChart>
      <VegaFlex direction={'col'} gap={'size-16'} data-testid="legend-col-1" style={{ width: '200px' }}>
        {data!.slice(0, 3).map((entry, index) => (
          <LabelMarker
            key={`label-${index}`}
            isMobile={isMobile}
            amount={entry.paymentTypeTotalAmountFormatted}
            color={typeColors[index]}
            name={entry.paymentType}
            percentage={entry.paymentTypePercentage}
            index={entry.index}
            selected={selected === entry.index}
            setSelected={() => handleSelected(entry.index)}
          />
        ))}
      </VegaFlex>
      <VegaFlex
        className={styles.legendCol2}
        direction={'col'}
        gap={'size-16'}
        style={{ width: '200px' }}
        data-testid="legend-col-2"
      >
        {data!.slice(3, 6).map((entry, index) => (
          <LabelMarker
            key={`label-${index}`}
            isMobile={isMobile}
            amount={entry.paymentTypeTotalAmountFormatted}
            color={typeColors[index + 3]}
            name={entry.paymentType}
            percentage={entry.paymentTypePercentage}
            index={entry.index}
            selected={selected === entry.index}
            setSelected={() => handleSelected(entry.index)}
          />
        ))}
      </VegaFlex>
    </VegaFlex>
  );
};

export default PaymentTypeDonut;
