import { CallToActionNames } from 'types/notification';
import { PendingOwner, UserInterface } from 'types/user';

export const newOwnerRequestCardDismissedKey = '_newOwnerRequestCardDismissed_';

export const displayNewOwnerMessage = (
  isSuccess: boolean,
  pendingOwners: PendingOwner[] | undefined,
  user: UserInterface,
  FetchTranslationMessage: (key: string) => string,
  SetNewRequestMessage: (requestMessage: string) => void,
  setCardVisibility: (isVisible: boolean) => void,
) => {
  if (isSuccess) {
    if (pendingOwners && pendingOwners.length) {
      let email = '';
      let message = '';

      const filteredPendingOwners = removeDismissedPendingOwners(user, pendingOwners);

      switch (filteredPendingOwners.length) {
        case 0:
          return;
        case 1:
          email = filteredPendingOwners[0].email;
          message = FetchTranslationMessage('new-single-owner-request-text-body');
          break;
        default:
          message = FetchTranslationMessage('multiple-owner-request-text-body');
      }

      SetNewRequestMessage(email ? `${email} ${message}` : `${message}`);
      setCardVisibility(true);
    }
  }
};

export function fetchCacheStorageByKey(key: string) {
  return sessionStorage.getItem(key);
}

export function generateKey(user: UserInterface, pendingOwner: PendingOwner) {
  return `${user.activeCustomer?.idValue}${user.userEmail}${newOwnerRequestCardDismissedKey}${pendingOwner.accountId}`;
}

export const logCallToAction = (
  user: UserInterface,
  pendingOwners: PendingOwner[],
  callToAction: (ctaName: CallToActionNames) => Promise<void>,
) => {
  callToAction(CallToActionNames.DismissNewOwnerRequest).finally(() => {
    try {
      pendingOwners.forEach((owner) => {
        sessionStorage.setItem(generateKey(user, owner), owner.email);
      });
    } catch (e) {
      console.log(e);
    }
  });
};

function removeDismissedPendingOwners(user: UserInterface, pendingOwners: PendingOwner[]) {
  const filteredPendingOwners: PendingOwner[] = [];

  if (pendingOwners.length == 0) {
    return pendingOwners;
  }

  pendingOwners.forEach((owner: PendingOwner) => {
    const previouslyDismissedRequest = fetchCacheStorageByKey(generateKey(user, owner));

    if (previouslyDismissedRequest == null || previouslyDismissedRequest !== owner.email) {
      filteredPendingOwners.push(owner);
    }
  });

  return filteredPendingOwners;
}
