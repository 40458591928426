import React, { useContext, useMemo, useState } from 'react';

import { VegaBox, VegaButtonLink, VegaFont, VegaGrid, VegaIcon } from '@heartlandone/vega-react';
import router from 'next/router';
import { useTranslation } from 'next-i18next';

import notificationService from 'api/services/notification';
import LayoutCard from 'components/layout/layoutCard';
import { UserContext } from 'components/user/auth/userContext';
import usePendingOwners from 'hooks/usersManagement/usePendingOwners';
import { LayoutTypes } from 'types/app';

import { displayNewOwnerMessage, logCallToAction } from './utility/newOwnersCard.util';

const NewOwnersCard: React.FC = () => {
  const user = useContext(UserContext);

  const { t } = useTranslation('new-owners-card');
  const [cardIsVisible, setCardVisibility] = useState<boolean>(false);
  const [newRequestMessage, setNewRequestMessage] = useState<string>('');
  const { isSuccess, data } = usePendingOwners();

  useMemo(() => {
    displayNewOwnerMessage(isSuccess, data, user, t, setNewRequestMessage, setCardVisibility);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleCloseButton = () => {
    if (data) {
      logCallToAction(user, data, notificationService.DismissCallToAction);
    }

    setCardVisibility(false);
  };

  return (
    <>
      {cardIsVisible && (
        <LayoutCard type={LayoutTypes.CardTransparent}>
          <VegaBox corners="rounded-12" padding="size-24" backgroundColor={'bg-status-info'}>
            <VegaGrid column={3} row={'1-auto'} style={{ gridTemplateColumns: `76px 1fr` }}>
              <div style={{ gridRow: 'span 2' }}>
                <VegaIcon color="text-black" icon="fa-solid fa-bell-exclamation" size="size-32" />
              </div>
              <VegaFont variant="font-h5" color="text-black" style={{ gridColumn: '2 / span 2', gridRow: '1' }}>
                {t('new-owner-request')}
              </VegaFont>
              <VegaFont variant="font-field-label" color="text-black" style={{ gridColumn: '2 / span 2' }}>
                {newRequestMessage}&nbsp;
                <VegaButtonLink label={t('user-page-link-text')} onVegaClick={() => router.push('/users')} />.
              </VegaFont>
              <VegaIcon
                color="text-black"
                icon="fa-solid fa-x"
                data-testid="closeNewOwnersMessage"
                size="size-16"
                style={{ gridColumn: '3 / span 1', gridRow: '1' }}
                onClick={() => handleCloseButton()}
              />
            </VegaGrid>
          </VegaBox>
        </LayoutCard>
      )}
    </>
  );
};

export default NewOwnersCard;
