import { useMemo } from 'react';

import { VegaFlex } from '@heartlandone/vega-react';
import { useTranslation } from 'next-i18next';
import { Cell, Pie, PieChart } from 'recharts';

import { DeviceIsMobile } from 'utility/showOnDevice';

import { emptyChartContainerId, emptyColors } from '../../chart.constants';
import LabelMarker from '../../labelMarker';

import styles from '../paymentTypeDonut.module.css';

const EmptyDonutChart: React.FC = () => {
  const { t } = useTranslation('reports');
  const isMobile = DeviceIsMobile();

  const placeholderData = useMemo(() => {
    return [
      { name: t('paymentType.card'), value: 33, index: 0 },
      { name: t('paymentType.cash'), value: 33, index: 1 },
      { name: t('paymentType.other'), value: 33, index: 2 },
    ];
  }, [t]);

  return (
    <VegaFlex
      className={styles.paymentTypeChartContainer}
      direction={{ XL: 'row', L: 'row', M: 'row', S: 'col' }}
      gap={'size-48'}
      style={{ width: '100%' }}
      data-testid={emptyChartContainerId}
    >
      <PieChart width={210} height={210}>
        <Pie cx={100} data={placeholderData} cy={100} innerRadius={60} outerRadius={90} dataKey={'value'}>
          {placeholderData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={emptyColors[entry.index]}
              style={{ outline: 'none', cursor: 'pointer' }}
              stroke={emptyColors[entry.index]}
              strokeWidth={0}
              className={styles.pieSector}
            />
          ))}
        </Pie>
      </PieChart>
      <VegaFlex direction={'col'} gap={'size-16'} data-testid="legend-col-1" style={{ width: '200px' }}>
        {placeholderData.map((entry, index) => (
          <LabelMarker
            key={`label-${index}`}
            isMobile={isMobile}
            amount={'$0.00'}
            color={emptyColors[index]}
            name={entry.name}
            percentage={'0%'}
            index={entry.index}
            selected={false}
            setSelected={() => {}}
          />
        ))}
      </VegaFlex>
    </VegaFlex>
  );
};

export default EmptyDonutChart;
