/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import { useContext } from 'react';

import { VegaBox, VegaGrid, VegaIcon } from '@heartlandone/vega-react';
import { useTranslation } from 'next-i18next';

import LayoutCardHeader from 'components/layout/layoutCardHeader';
import { UserContext } from 'components/user/auth/userContext';
import { DeviceIsMobile } from 'utility/showOnDevice';

import { discoveryProductsList } from './constants';
import ProductsCard, { ProductsCardProps } from './productsCard/productsCard';

import styles from './discoverProducts.module.css';

const DiscoverProducts: React.FC = () => {
  const isMobile = DeviceIsMobile();
  const { t } = useTranslation('discover');

  const user = useContext(UserContext);
  let prodList = discoveryProductsList; // need to make a copy of the list to filter it

  if (user.userHasActivePaymentsAccounts) {
    prodList = prodList.filter((card) => card.title !== 'productCards.paymentsAnywhereAnytime.title');
  }
  if (user.userHasActivePayrollAccounts) {
    prodList = prodList.filter((card) => card.title !== 'productCards.allInOnePayrollHrSoftware.title');
  }

  return (
    <React.Fragment>
      <LayoutCardHeader>
        <VegaIcon icon={'fa fa-circle-question'} size={'size-20'} /> {t('title')}
      </LayoutCardHeader>
      <VegaBox margin={{ top: 'size-24' }}>
        <VegaGrid className={styles.discoveryProductsLayout} column={isMobile ? '1-auto' : '2-auto'}>
          {prodList.map((p: ProductsCardProps) => {
            return <ProductsCard key={p.id} {...p} />;
          })}
        </VegaGrid>
      </VegaBox>
    </React.Fragment>
  );
};

export default DiscoverProducts;
