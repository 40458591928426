import React, { useContext } from 'react';

import { VegaButtonLink, VegaCard, VegaChip, VegaFlex, VegaFont, VegaIcon } from '@heartlandone/vega-react';
import { useTranslation } from 'next-i18next';

import { getNameLabel } from "components/dashboard/salesRepresentativeCard/salesRepresentativeCard.util";
import { UserContext } from "components/user/auth/userContext";
import useSalesRepresentativeData from "hooks/reports/useSalesRepresentativeData";
import { maskPhone } from 'utility/helper/helperFunctions';

import styles from './salesRepresentativeCard.module.css';

const SalesRepresentativeCard: React.FC = () => {
  const { t } = useTranslation('sales-representative');
  const { isVerified } = useContext(UserContext);
  const { data, isFetching, isError } = useSalesRepresentativeData(() => {}, isVerified);

  return (
    <VegaCard padding={'size-24'} className={styles.salesRepresentativeCard}>
      <VegaFlex direction="col" gap="size-4">
        <VegaChip size="extra-small" bgColor="bg-accent2-secondary" text={t('sales-representative')} />
        <VegaFont color="text-primary" variant={'font-h6'}>
          {getNameLabel(data, isError, isFetching, t)}
        </VegaFont>
        {data?.phoneNumber && (
          <VegaFlex direction="row" gap="size-8" alignItems="center">
            <VegaIcon style={{ minWidth: '20px' }} icon="far fa-phone-hangup" color="text-input-placeholder" />
            <VegaFont color="text-secondary" variant={'font-field-label-sm'}>
              {maskPhone(data.phoneNumber)}
            </VegaFont>
          </VegaFlex>
        )}
        {data?.email && (
          <VegaFlex direction="row" gap="size-8" alignItems="center">
            <VegaIcon style={{ minWidth: '20px' }} icon="far fa-message-lines" color="text-input-placeholder" />
            <VegaFont color="text-secondary" variant={'font-field-label-sm'}>
              <VegaButtonLink data-testid="link-mail" size="small" link={`mailto:${data.email}`}>
                {data.email}
              </VegaButtonLink>
            </VegaFont>
          </VegaFlex>
        )}
      </VegaFlex>
    </VegaCard>
  );
};

export default SalesRepresentativeCard;
